
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-app {
    background: rgb(23 23 23);
    color: #ffffff;
}

.base-landing {
    background-color: #2d3d4a;
}

.required {
    font-size: 15px;
}

.text-success {
    color: #044317;
}

.bordered-input {
    border: 2px solid #044317;
}


.text-app {
  color: #4a3023 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to
   increase or decrease the speed of the spinner*/
}

.badge {
  display: inline-block;
  width: 150px;
}

.small {
  font-size: 11px;
}


a {
    color: #000;
    text-decoration: none;
    display: block;
}

.Logo {
    max-width: 100%;
}

.label {
    font-size: 11px;
    text-transform: uppercase;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.bg-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transition: background-size ease-in .3s;
    -webkit-transition: background-size ease-in .3s;
}

.bg-img:hover {
    background-size: cover;
}


.article-item {
    height: 350px;
}

.article-item:hover {
    border-color: black !important;
}

.star {
    font-size: 12px;
}


.bg-orange {
    background: #FFFFE3;
}

.scanResult {
    position: absolute;
    top: 120px;
    left: 20px;
    zIndex: 99999;
    color: deeppink;
    font-weight: bold;
    font-size: 60px;
}


